
import {
  defineComponent,
  computed,
} from 'vue';

import { useStore } from '@/store/index';
import { MangaActionTypes } from '@/store/types/action.type';

export default defineComponent({
  name: 'chaptersRead',
  setup() {
    const store = useStore();

    const chaptersRead = computed(() => store.getters['mangaStore/chaptersRead']);

    const headers = [
      'Manga',
      'ChapterName',
      'PageNumber',
      'Is read',
      'Created at',
      'Updated at',
      'edit',
    ];

    store.dispatch(MangaActionTypes.getChaptersRead);

    const formatDate = (date: Date | string): string => {
      const d = new Date(date);
      const month = `0${d.getMonth() + 1}`.slice(-2);
      const day = `0${d.getDate()}`.slice(-2);
      const year = d.getFullYear();
      const hours = `0${d.getHours()}`.slice(-2);
      const minutes = `0${d.getMinutes()}`.slice(-2);

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const deleteChapterRead = async (chapterId: string) => {
      await store.dispatch(MangaActionTypes.deleteChapterReading, chapterId);
      await store.dispatch(MangaActionTypes.getChaptersRead);
    };

    return {
      chaptersRead,
      headers,
      formatDate,
      deleteChapterRead,
    };
  },
});
